// extracted by mini-css-extract-plugin
export var container = "searchBox-module--container--G6dtI";
export var filter = "searchBox-module--filter--3ClDd";
export var filterItem = "searchBox-module--filter-item--g0UUe";
export var filterText = "searchBox-module--filter-text--+PA-p";
export var filterTextSelected = "searchBox-module--filter-text-selected--ZLgvH searchBox-module--filter-text--+PA-p";
export var icon = "searchBox-module--icon--avlNi";
export var input = "searchBox-module--input--xNCVB";
export var toggle = "searchBox-module--toggle--m8yPz";
export var toggleIcon = "searchBox-module--toggle-icon--acyJa";
export var toggleText = "searchBox-module--toggle-text--vOqWc";